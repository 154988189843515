import * as _base64Js2 from "base64-js";

var _base64Js = "default" in _base64Js2 ? _base64Js2.default : _base64Js2;

import { dew as _decodeDew } from "./decode";
import { dew as _dictionaryBinDew } from "./dictionary.bin.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var base64 = _base64Js;
  /**
   * The normal dictionary-data.js is quite large, which makes it 
   * unsuitable for browser usage. In order to make it smaller, 
   * we read dictionary.bin, which is a compressed version of
   * the dictionary, and on initial load, Brotli decompresses 
   * it's own dictionary. 😜
   */

  exports.init = function () {
    var BrotliDecompressBuffer = _decodeDew().BrotliDecompressBuffer;

    var compressed = base64.toByteArray(_dictionaryBinDew());
    return BrotliDecompressBuffer(compressed);
  };

  return exports;
}